window.toggleMainNav = function toggleMainNav() {
  const navButton = document.getElementById('navButton');
  const mainNav = document.getElementById('navContainer');
  const navSplash = document.getElementById('navSplash');
  navButton.classList.toggle('opened');
  mainNav.classList.toggle('opened');
  navSplash.classList.toggle('opened');
  document.body.classList.toggle('fixedPosition');
};

window.toggleFooterFormPlaceholder = function toggleFooterFormPlaceholder() {
  const footerForm = document.getElementById('footerNewsletterForm');
  const emailField = document.getElementById('footerNewsletterEmail');
  if (emailField.value) {
    footerForm.classList.add('hasEmail');
  } else {
    footerForm.classList.remove('hasEmail');
  }
};

function popModalNotice() {
  document.getElementById('modalNotice').classList.add('open');
  document.getElementById('darkLayer').classList.add('visible');
}
function closeModalNotice() {
  document.getElementById('modalNotice').classList.remove('open');
  document.getElementById('darkLayer').classList.remove('visible');
}
const muradocsLink = document.getElementById('muradocsLink');
const modalNoticeClose = document.getElementById('modalNotice-close');
if (muradocsLink) {
  document.getElementById('muradocsLink').onclick = popModalNotice;
  document.getElementById('modalNotice-close').onclick = closeModalNotice;
}
